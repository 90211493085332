<template>
  <base-section id="map" space="0">
    <iframe allowfullscreen aria-hidden="false" frameborder="0" height="500"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.2558420560576!2d77.40999847479611!3d28.501949375736334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce70a5fd29775%3A0xc2d7649b7427d0e1!2sHedge%20Vision%20Systems%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1689242371899!5m2!1sen!2sin"
      loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border:0; display: block;" tabindex="0"
      width="100%" />
  </base-section>
</template>

<script>
export default {
  name: 'SectionMap',

  provide: {
    heading: { align: 'center' },
  },
}
</script>
